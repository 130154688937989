.common-data-table {
  width: 100%;
  .p-datatable,
  .p-treetable {
    .p-datatable-table {
      border-collapse: collapse;
      .disabled-grey {
        cursor:auto !important;
      }
      .pi-chevron-down {
        font-size: 20px !important;
      }
    }
    &.p-datatable-scrollable {
      overflow: auto !important;
    }
    .p-datatable-wrapper {
      word-break: break-word;
      overflow: visible !important;
    }

    &.p-treetable-auto-layout{
      &>.p-treetable-wrapper {
        overflow: visible !important;
      }
    }

    .p-datatable-scrollable-header {
      background: transparent;
    }

    .p-datatable-thead,
    .p-treetable-thead {
      border-bottom: 2px solid $button-border;
      font-weight: bold;

      &>tr {
         background-color: $white;
        &>th {
          border: 0;
          background-color: transparent;
          text-align: left;
          word-break: keep-all;
          &.p-sortable-column {
            .pi-sort-alt {
              &:before {
                content: "\e915";
              }
            }
            .pi-sort-amount-down {
              &:before {
                content: "\e913";
              }
            }
            .pi-sort-amount-up-alt {
              &:before {
                content: "\e914";
              }
            }
            .p-sortable-column-badge {
              display: none;
            }
            &:focus {
              box-shadow: none;
            }

            &:hover {
              background: transparent;
            }

            &.p-highlight {
              color: $button-border;
            }
          }
        }
      }
    }

    .p-sortable-column {
      &.p-highlight {
        .p-sortable-column-icon {
          color: $button-border;
        }
      }
    }

    .p-datatable-tbody,
    .p-treetable-tbody {
      border-bottom: 2px solid $button-border;

      &>tr {
        &:focus {
          outline: 0;
        }

        &.p-highlight {
          background-color: transparent;
          color: $subnav-heading;
        }

        &:nth-child(even) {
          background-color: $table-even-row-background;
          &.p-highlight {
            background-color: $table-record-background;
            color: $subnav-heading;
          }
        }

        &>td {
          border: 0;
          word-break: break-all;
          a:hover{
            text-decoration: none;
          }
        }
      }
    }

    .filtered-row-input {
      .igfiltersec {
          .timePickerForm {
            .p-dropdown {
              min-width: 100% !important;
              height: 35px;
            }
          }
        }
      input {
        height: 35px;
        border: 1px solid $border-color;
      }
      .p-dropdown {
        width: 100%;
        height: 35px;
      }
      .emtr-datepicker {
        .p-float-label > label {
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
  }

  &.authorized-agent-table {
    .p-datatable {
      border: 1px solid $textarea-label-color;

      .p-datatable-tbody {
        border-bottom: none !important;
      }
    }
  }

    &.asset-group-table {
      .p-treetable-tbody {
        td:first-child {
          border-right: 1px solid $table-record-border !important;
        }
  
        .p-indeterminate {
          .p-checkbox-icon {
            color: $white !important;
          }
        }
      }
    }

  &.asset-group-table-no-border {
    .p-treetable-tbody {
      border-bottom: none !important;
    }
  }

  &.manage-client-list-table {
    .filter-header-input {
      th {
        padding-bottom: 0 !important;
      }
    }
    .filtered-row-input {
      th {
        padding-top: 0 !important;
        padding-bottom: 5px !important;
      }
    }
  }

  &.create-user-table {
    thead, tbody tr {
      display:table;
      width:100%;
      table-layout:fixed;
    }
    .p-datatable-thead {
    width: calc(100%) /* scrollbar is average 1em/16px width, remove it from thead width */;
    }
    .p-datatable-tbody {
      display: block;
      max-height: 180px;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      .p-multiselect {
        min-width: auto;
      }
    }
  }
}

.common-data-table, .common-pagination {
  .p-paginator {
    background: transparent;
    border: 0;
    padding: 20px 0 0 0;
    .p-paginator-first {
      .pi-angle-double-left {
        &:before {
          content: "\e916";
        }
      }
    }
    .p-paginator-last {
      .pi-angle-double-right {
        &:before {
          content: "\e917";
        }
      }
    }
    .p-paginator-prev {
      .pi-angle-left {
        &:before {
          content: "\e904";
        }
      }
    }
    .p-paginator-next {
      .pi-angle-right {
        &:before {
          content: "\e905";
        }
      }
    }
    .p-paginator-pages {
      .p-paginator-page {
        margin: 0 6px;
        min-width: inherit;
        min-width: 1.5em;
        box-shadow: none;
        &:not(.p-highlight) {
          &:hover { 
            background-color: #e0e0e0;
            color: #333333;
          }
        }
        &.p-highlight {
          background: transparent;
          color: $button-border;
          font-weight: bold;
          box-shadow: none;
        }
      }
    }
  }
}


.tbl-rec-count {
  .table-data-count {
    background: $table-record-background;
    font-family: $font-family-regular;
    border: 1px solid $table-record-border;
    padding: 9px !important;

    .table-left {
      border-right: 1px solid $table-record-border;
    }
  }
}


/*************************
FILTERED TABLE

**************************/


.filtered-table {
  table {
    th {
      border-left: 0 !important;
      &:last-child {
        border-right: 0 !important;
      }
    }
    tr.expanded-row-header, tr.expanded-row-body {
      th, td {
        padding: 0;

        border-bottom: 1px solid #000 !important;

      }
    }

    .expanded-row-header{
      th {
        &:first-child, &:last-child {
          border-top: 0 !important;
          border-bottom: 0 !important;
          margin-top: 30px;
        }
      }
    }


    .no-border-top th{
      border-top: 0 !important;
      &:first-child, &:last-child {
        border-bottom: 0 !important;
      }
    }
    .no-border-bottom th{
      border-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.filtered-table {
  .p-datepicker-calendar{
    thead {
      tr {
        th {
          border:0 !important;
          padding: 0 8px 5px 8px !important;
        }
      }
    }
    tbody{
      tr {
        td {
          border-bottom: 0 !important;
        }
      }
    }
  }
}
