//@import "global";
//@import "variable";
//
//.client-dropdown {
//  .pi-chevron-down {
//    font-size: $font-2x !important;
//    color: $white;
//
//    &:before {
//      content: "\e906";
//    }
//  }
//
//  .ui-dropdown {
//    background: transparent;
//    border: none;
//    width:200px;
//    .ui-dropdown-panel {
//      min-width: 100%;
//      width: inherit;
//      @include border-radius(0px);
//      .ui-dropdown-items-wrapper {
//        overflow-y: auto;
//        overflow-x: hidden;
//        width: inherit;
//        li{
//          display: block;
//          padding: 0;
//
//          > div {
//           padding: 0.400em 0.857em;
//          }
//        }
//      }
//    }
//    .ui-dropdown-label {
//      background-color: $footer-text;
//      color: $white;
//      text-align: left;
//      @include border-radius(0px);
//    }
//
//    .ui-dropdown-trigger {
//      background-color: $footer-text;
//
//      .ui-dropdown-trigger-icon {
//        margin-left: -.9em;
//      }
//    }
//  }
//}
//
///* Reports-Dropdown customization */
//.report-dropdown {
//  .ui-dropdown {
//    padding: 1rem;
//    width: 100%;
//    border-radius: 0;
//  }
//
//  .ui-dropdown-label {
//    vertical-align: middle;
//    padding: 0;
//    color: $black;
//    font-size: 16px;
//  }
//}
//
///* view history drop down */
//.view-history-holder {
//  .ui-dropdown {
//    width: 90% !important;
//    @include border-radius(0px);
//  }
//}
//
//.ui-float-label {
//  .pi-chevron-down {
//    &:before {
//      content: "\e906" !important;
//      color: $black !important;
//    }
//  }
//
//
//  .ui-dropdown-label {
//    padding-top: 15px !important;
//    font-weight: bold !important;
//    color: $black !important;
//  }
//}
//
//.chart-dropdown {
//  .ui-dropdown {
//    min-width: 4em;
//  }
//}
//
//.rule-detail  {
//  .ui-dropdown{
//    min-width: 44em;
//  }
//}
///*Take-Action Menu styles*/
//.take-action-container {
//  .ui-dropdown {
//    min-width: 9.5em !important;
//  }
//  .ui-dropdown, .ui-dropdown-label, .ui-dropdown-trigger {
//    color: $white !important;
//    background: $button-border !important;
//    padding: 6px;
//    font-weight: bold;
//    border-radius: 0;
//  }
//
//  .ui-dropdown-item:hover {
//    background: $ActionMenu-li-hover-color !important;
//    color: $subnav-heading !important;
//  }
//
//  .ui-dropdown-panel {
//    border: 1px solid $button-border;
//  }
//  .ui-dropdown-items {
//    .ui-dropdown-item.ui-state-highlight:hover {
//      color: #fff !important;
//      background: $button-border !important;
//    }
//  }
//  .pi-chevron-down:before {
//    content: "\e913";
//  }
//  .ui-dropdown-trigger-icon {
//    line-height: 14px;
//  }
//}

@import "global";
@import "variable";

.client-dropdown {
  .pi-chevron-down {
    font-size: $font-2x !important;
    color: $white;

    &:before {
      content: "\e906";
    }
  }

  .p-dropdown {
    background: #cccccc70;
    border: none;
    width:200px;
    .p-dropdown-panel {
      min-width: 100%;
      width: inherit;
      @include border-radius(0px);
      .p-dropdown-items-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        width: inherit;
        .p-scroller {
          overflow-x: hidden;
        }
        li{
          display: block;
          padding: 0;

          > div {
            padding: 0.400em 0.857em;
          }
        }
        .p-dropdown-empty-message {
          text-align: left;
          padding: 0.5em;
        }
      }
    }
    .p-dropdown-label {
      background-color: $footer-text;
      color: $white;
      text-align: left;
      @include border-radius(0px);
    }

    .p-dropdown-trigger {
      background-color: $footer-text;

      .p-dropdown-trigger-icon {
        margin-left: -.9em;
      }
    }
  }
}

/* Reports-Dropdown customization */
.report-dropdown {
  .p-dropdown {
    padding: 1rem;
    width: 100%;
    border-radius: 0;
  }

  .p-dropdown-label {
    vertical-align: middle;
    padding: 0;
    color: $black;
    font-size: 16px;
  }
}

/* view history drop down */
.view-history-holder {
  .p-dropdown {
    width: 90% !important;
    @include border-radius(0px);
  }
}

.p-float-label {
  .pi-chevron-down {
    &:before {
      content: "\e60a" !important;
      color: $black !important;
    }
  }


  .p-dropdown-label {
    padding-top: 12px !important;
    font-weight: bold !important;
    color: $black !important;
    font-family: $font-family-regular;
  }
}

.chart-dropdown {
  .p-dropdown {
    min-width: 4em;
  }
}

.rule-detail  {
  .p-dropdown{
    min-width: 44em;
  }
}
/*Take-Action Menu styles*/
.take-action-container {
  .p-dropdown {
    min-width: 8.5em !important;
  }
  .p-dropdown, .p-dropdown-label, .p-dropdown-trigger {
    color: $white !important;
    background: $button-border !important;
    padding: 5px;
    font-weight: bold;
    border-radius: 0;
    width: 1.357rem;
  }

  .p-dropdown-item:hover {
    background: $ActionMenu-li-hover-color !important;
    color: $subnav-heading !important;
  }

  .p-dropdown-panel {
    border: 1px solid $button-border;
  }
  .p-dropdown-items {
    .p-dropdown-item.p-state-highlight:hover {
      color: #fff !important;
      background: $button-border !important;
    }
  }
  .pi-chevron-down:before {
    content: "\e913";
  }
  .p-dropdown-trigger-icon {
    line-height: 14px;
  }
 }

.report-align{
.buttons {
  padding: 9px 15px;
}
}