@import "variable";


.select-rule-type .rule-type-textbox input:disabled[formcontrolname=reviewRuleName], 
input:disabled[formcontrolname=groupName],
input:disabled[formcontrolname=description],
textarea:disabled[formcontrolname=description]
{
  pointer-events: none !important;
  opacity: 1;
}

.select-rule-type {
  .p-inputtext {
    &.ng-dirty.ng-invalid {
      border: none
    }
  }
  .calender-width{
    .p-float-label {

      input {
        width:75% !important;
      }
    }
  }
  .p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label{
    top: -.25rem !important;
  }

  .p-float-label {
     input {
      width: 100%;
      height: 25px;
      border: 0;
      transition: none;
      -webkit-appearance: none;
      font-size: 16px;
      &.p-default.business-unit-textbox {
        &.ng-valid ~ label {
          top: 50%;
        }

        &.p-filled ~ label,
        &:focus ~ label {
          font-size: 10px;
        }
      }
      &:focus ~ label {
        color: $iconColor;
      }
    }

    textarea:focus ~ label {
      color: $textarea-lbl;
    }

    /*.p-element:disabled + label {
      opacity: 0.5;
    }*/

    .p-inputtext:enabled:focus{
      box-shadow: none;
    }
  }

  .rule-type-textbox {
    border: 1px solid $textarea-label-color;

    input:disabled,
    &.input-disabled {
      background: $disabled-color !important;
      color: $black;
      opacity: 1;
    }


    &.invalidField {
      border-color: $error-field-border !important;
      label {
        color: $error-field-border !important
      }
    }

    &.ruleWarning {
      border-color: #f7df8c;
    }

    .ruleNameWarningColor {
      color: #f7df8c;
    }

    &:focus-within {
      border: 1px solid $button-border;
    }
  }

  .group-desc {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  textarea {
    border: none;
    background: transparent;
    outline: none;
    width: 100%;
  }
}

.info-icon-button-border {
  button#tooltip-title {
    border: none;
    width: 18px;
    height: 0;
    line-height: 0;
  }

  button#tooltip-title:focus {
    outline: none;
  }

  .sdps-tooltip--hide {
    display: none;
  }
  .sdps-button {
    height: 0px;
  }
}


/* x sign getting overlapped in toast message*/

.toast-confirmation .sdps-messaging > div {
  width: 99%;
}
