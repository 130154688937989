@import "variable";
@mixin border-radius($value) {
    border-radius: $value !important;
    -moz-border-radius: $value;
    -webkit-border-radius: $value;
}

@mixin transform-translate($value1, $value2) {
    transform: translate($value1, $value2);
    -webkit-transform: translate($value1, $value2);
    -moz-transform: translate($value1, $value2);
    -ms-transform: translate($value1, $value2);
}

body * {
    font-family: CharlesModern-Regular, Arial, Helvetica, sans-serif;
    font-size: 16px;
}

.highlight-color{
  color: red;
}

.line-height-0 {
    line-height: 0;
}

.no-margin {
    margin: 0 !important;
}

.w-25-per {
    width: 25%;
}

.w-10-per {
    width: 10%;
}

.w-5-per {
    width: 5%;
}

.w-13-per {
  width: 13%;
}

.w-15-per {
    width: 15%;
}

.w-20-per {
  width: 20%;
}

.w-18-per {
    width: 20%;
  }

.w-40-per {
    width: 40%;
}

.w-50-per {
    width: 50%;
}

.w-65-per {
    width: 65%;
}

.w-95-per {
  width: 95%;
}

.w-100-per {
    width: 100%;
}

.step-border-box {
    border: 1px solid $table-record-border;
}

.line-height-nml {
    line-height: normal;
}

::-moz-placeholder {
    opacity: 1;
}

.cursor {
    cursor: pointer;
    &:focus{
      outline: 1px solid #000;
    }
}

textarea {
    resize: none;
}

.sch-x.schwab-cross-icon {
    font-size: $font-3x;
}

.gutter-10 {
    padding: 10px;
}

.align-item-center {
  align-items: center;
}

.mt-10 {
  margin-top: 10px;
}

@for $i from 1 through 100 {
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}
/*Modified the Fusion Modal*/

@media (min-width: 768px) {
    .sdps-modal__dialog--flex--large {
        max-width: 90%;
        min-width: 80%;
    }
}

@media (max-width: 1600px) {
  .expense-ratio {
    &.mutual-fund-asset-group-dropdown { 
      .ui-dropdown {
        width: 70% !important;
      }
    }
  }
}

.input-group-field.ng-invalid {
    input {
        border: 0 !important;
    }
}

.font-14 {
   font-size: 14px;
 }

.font-21 {
  font-size: 21px;
}

.font-10 {
  font-size: 10px;
}

.sdps-grid-fluid>div[class^=col] {
    margin-bottom: 15px;
}

.word-break {
    word-break: break-word;
}
.word-keep-all {
  word-break: keep-all;
}

.disabled {
    background: $disabled-color;
    cursor: not-allowed !important;
    opacity: 1;
    label{
      color:$black;
    }
    span{
      color:$black;
    }
}
.input-disabled{
  label{
    color: $black;
  }
}
.select-report-type .p-float-label input:disabled{
  background-color: $disabled-color !important;
  color: $black;
  opacity:1;
}

textarea[disabled] + label {
  color: $disabled-color;
}

.w-200-px {
    width: 200px;
}

.w-6-per {
    width: 6%;
}

.expanded-row {
    .expanded-row-item {
        .emtr-datepicker {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}

.sdps-modal__footer {
    display: -ms-flexbox;
    -ms-flex-pack: center;
}

.disabled-option {
    color: #ccc;
    cursor: default;
}

sup {
    font-family: CharlesModern-Regular, Arial, Helvetica, sans-serif;
    font-size: 15px;
}
.full-width {
  width:100%;
}
.vertical-align-middle {
  vertical-align: middle;
}

.align-items-flex-start {
  align-items: flex-start;
}

.addedEmp { color: $activate-button-color !important}
.removedEmp { color: $expired-rule-color !important}

.disabled-cursor-option {
  color: $disabled-color;
 // cursor: not-allowed !important;
}
.disabled-cursor-option:has(input:disabled[formcontrolname=masterAccountNumber]) {
  background-color: $disabled-color;
  cursor: not-allowed;
}

// .report-textbox-border{
//   &.disabled-cursor-option {
//     background-color: $disabled-color;
//   }
// }

.create-user {
  padding: 0 !important;

  .p-float-label {
    .p-dropdown-label {
      font-weight: normal !important;
      font-family: $font-family-regular;
      padding-left: 14px !important;
      font-size: 14px;
    }
    &>.p-inputwrapper-filled ~ label {
        top:1em;
        left:1.5em;
    }
    .p-dropdown {
        width: 100% !important;
        height: 100% !important;
        padding: 7px !important;
        border-radius: 0 !important;
    }
  }
}

.country-code-dropdown-disable {
    padding: 0 !important;
    cursor: not-allowed !important;
    pointer-events: auto !important;

    .p-float-label {
      .p-dropdown-label {
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
        padding-left: 14px !important;
      }
      &>.p-inputwrapper-filled ~ label {
        top:1em;
        left:1.5em;
        opacity: 0.5;
      }
      .p-dropdown {
        width: 100% !important;
        height: 100% !important;
        padding: 7px !important;
        border-radius: 0 !important;
        background-color: $disabled-color !important;
        border: 1px solid $disabled-color;
        opacity: 1;
      }
      p-dropdown[formcontrolname=countryCode] + label {
        color:  $black !important;
        opacity: 1;
      }
      
      border: solid 0.5px #98A4AE
    }
}

     .disabled-grey {
       p-inputmask[formcontrolname=phoneNumber]+label {
         color: $black !important;
         opacity: 1;
       }
     }

.ui-disable-nav-tab {
  color: #777 !important;
}

.show-scrollable-list {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  list-style-position: inside;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}
.display-inline-block {
  display: inline-block;
}

.remark-container {
  .e-input-textarea {
    border: 1px solid $border-color;
    background-color: $white;

    &:focus-within {
      border: 1px solid $textarea-lbl;
    }

    .p-inputtext {
      &:enabled:focus {
        border: 0px;
        box-shadow: none;
      }
    }

    em {
      position: absolute;
    }

    textarea {
      border-radius: 0px !important;
      border: 0px;
      outline: none;
      width: 100%;
      height: 37px;
      padding-top: 1em !important;
      overflow: hidden;
    }

    .p-float-label {
      textarea ~ label {
        color: #898989;
        top: 50%;
        font-size: 14px;
      }

      textarea {
        &.p-filled ~ label,
        &:focus ~ label {
          top: 0.6em;
          padding: 0 0 0 2px;
          background-color: $white;
          font-size: 13px;
        }
      }
    }
  }
}
.sdps-grid-container .radio_header {
  background-color: #037DAE;
  font-size: 18px;
  margin: 15px 0;
  padding: 15px;
  color: white;

  .p-radiobutton {
    border: 0px !important;
    .p-radiobutton-box.p-highlight {
      background-color: #ffffff;
      box-shadow: none;
      &:not(.p-state-disabled) {
        &:hover {
          background-color: #ffffff;
        }
    }
      .p-radiobutton-icon {
        background-color: #007ad9;
        width: 10px;
        height: 10px;
      }
    }
  }
}

.left-padding-60px {
  padding-left: 60px;
}

button, [type="button"], [type="reset"], [type="submit"]  {
  cursor: pointer;
}

[disabled] {
  cursor: not-allowed;
}
//uploadtype status bg
.request-list-filter {
  &.request-list-multiselect-font-size {
     .p-disabled {
      background-color: $disabled-color;
      opacity: 1;
     }
  }
}
@media screen and (max-width: 1600px) {
  .w-200-px {
      width: auto;
      display: flex;
    }
}


@media screen and (max-width: 1368px) {
  .editableTimepicker {
    &.request-list-header-dropdown {
      .p-dropdown {
        max-width: 6.5rem;
      }
    }
  }
}

.expanded-row-filter {
  border: 0;
  display: flex;
}

.disable-overlay-menu {
  border: 1px solid $table-record-border !important;
  background: $white !important;
  color: $border-color !important;
  border-radius: 0px !important;
  cursor: not-allowed;
}

.remark-container {
  .ems-checkbox {
    display:flex;
    padding-top: 0.245rem;
  }
}
.other-input{
  &.input-disabled {
    pointer-events: none;
  }
}
.ems-multiselect .item-selected {
font-size: 14px !important;
}
.access-type-dropdown {
  width:6.5rem;
  .p-disabled{
    background-color: $disabled-color;
    opacity: 1;
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
}
.ems-show-record .no-of-records{
  display: none;
}

.pointer-events-auto {
  pointer-events: auto;
}
.pointer-events-none {
  pointer-events: none;
}