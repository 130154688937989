.emtr-checkbox {
    &.ng-dirty.ng-invalid {
        .p-checkbox-box {
            border: 2px solid $textarea-label-color;
        }
    }

    .p-checkbox{
        .p-checkbox-box {
            @include border-radius(0px);
            border: 2px solid $textarea-label-color;

            &:not(.p-disabled) {
                &:hover {
                    border-color: $textarea-label-color;
                }
            }

            &.p-highlight {
                border-color: $button-border !important;
                background-color: $button-border !important;

              &:not(.p-disabled) {
                &:hover {
                  border-color: $button-border !important;
                  background-color: $button-border !important;
                }
              }
            }

            &.p-focus {
                box-shadow: 0px 0px 5px #000;
                border-color: #000;
            }

            .p-checkbox-icon {
                right: 1px;
                bottom: 1px;
                font-size: 12px;
                font-weight: bolder;
            }




        }
    }

    .p-checkbox-label {
        font-size: $font-normal;
        cursor: pointer;
        margin: 0;
        padding: 0 0 0 0.5em;
        &.p-disabled {
          opacity: 1;
          background: transparent !important;
        }
    }

    &.on-check {
        color: $textarea-label-color;
    }

    &.on-uncheck {
        color: $black;
    }

&.check-disabled {
  .p-checkbox {
    
    
    .p-checkbox-box {
      &.p-highlight {
        background: #037DAE !important;
        border-color: #037DAE !important;
        color: $black;
        opacity: 0.5;
        pointer-events: none;

        &:hover {
          color: $black;
        }
      }
    }
  }
}

  &.hide-checkbox-tick {
    .p-checkbox {
      cursor: not-allowed;
      .p-checkbox-box {
        background: $disabled-color !important;
        border-color: $disabled-color !important;
        color: $textarea-label-color;
        opacity: 1;
        pointer-events: none;
        &:hover {
          color: $black;
        }
      }
    }
  }

}


.emtr-checkbox-disable {
    .p-checkbox {
        .p-checkbox-box {
          &.p-highlight.p-disabled {
            background: $button-border !important;
            border-color: $button-border !important;
          }
        }
    }
}

.ems-checkbox{
  &:not(.p-disabled) .p-checkbox-box.p-focus {
    box-shadow: none;
  }
}

// .emtr-checkbox-disable {
//   .p-checkbox-icon {
//     color: $border-color !important;
//   }
// }


// .emtr-checkbox {
//   .p-checkbox-disabled{
//     cursor: not-allowed !important;
//     pointer-events: auto !important;
//   }
//   .p-checkbox {
//       .p-checkbox-box {
        
//         &.p-highlight.p-disabled {
//             background: none !important;
//             border-color: $border-color !important;
//             cursor: not-allowed !important;
//             pointer-events: auto !important;
//             .p-checkbox-icon {
//               right: 1px;
//               bottom: 1px;
//               font-size: 12px;
//               font-weight: bolder;
//               color: $border-color !important;
//           }
//         }
//       }
//   }
// }

// .emtr-radio {
//   .p-radiobutton-disabled, .p-disabled {
//     cursor: not-allowed !important;
//     pointer-events: auto !important;
//   }
// }
