/*tabs*/
@import "variable";
@import "global";

.emtr-tab {
    .p-tabview {
        .p-tabview-nav-container {
            margin-bottom: -2px;
        }
        
        .p-tabview-panels {
            background-color: $white;
            padding: 20px 0;
            border-top: 2px solid $active-link !important;
            outline-color: transparent;
            border-color: transparent;
            position: relative;
            z-index: 1;
        }
        &.p-component {
            .p-tabview-nav {
                
                li {
                    padding: 9px 25px;
                    outline-color: transparent;
                    background-color: $tab-background;
                    transition: none;
                    @include border-radius(0px);
                    &:not(.p-highlight):hover {
                        transition: none;
                        background-color: $tab-background;
                        @include border-radius(0px);
                        .p-tabview-nav-link {
                            background: transparent;
                        }
                    }
                    &.p-disabled {
                      background: none !important;
                      border:2px solid #7e7e7e;
                      .p-tabview-nav-link {
                        background: none !important;
                      }
                    }
                    .p-tabview-nav-link {
                        border-width: 0;
                        background: transparent;
                        transition: none;
                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .p-highlight {
                      background: $white;
                      border: 2px solid $active-link;
                      border-bottom-color: transparent;
                      position: relative;
                      z-index: 11;
                    .p-tabview-nav-link {
                      background: $white;
                      border: transparent;
                      //border-bottom-color: transparent;
                      @include border-radius(0px);
                      &:focus {
                        box-shadow: none;
                      }
                    }
                    &:hover {
                        background: $white;
                        border: 2px solid $active-link;
                        outline-color: transparent;
                        // margin-bottom: 1px;
                        border-bottom: 2px solid transparent;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    .p-tabview-title {
        color:$black;
    }

}
.p-tabview .p-tabview-nav li {
    margin-right:0px !important;
}
