@import 'variable';
@import 'global';

.ems-data-field{
    font-family: $font-family-regular;
    color:#898989;
    label{
        font-size:12px;
        display: block;
    }
}

.font-size {
    font-size: 14px;
}

.ems-input{

    height:44px;
    width:100%;

    display: inline-block;

    border:1px solid $border-color;
    font-family: $font-family-regular;
    background: $white;

    .p-float-label{
        height:100%;

        input{
            padding:15px 3px 4px 3px;
            border:0px;
            @include border-radius(0);
            background-color: transparent;
            color:#555;
            font-size:14px;
            width: 100%;
        }

        textarea {
            padding:4px 0px 4px 4px;
            border:0px;
            @include border-radius(0);
            height:100%;
            width:100%;
            background-color: transparent;
          &:focus{
            border: 1px solid $textarea-lbl !important;
          }
        }

        label{
            font-size:14px;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: .25em;
            top: 50%;
            margin-top: -.5em;
            transition: .3s ease all;
            -moz-transition: .3s ease all;
            -webkit-transition: .3s ease all;
            color: #898989 !important;
            line-height: 1;
        }
    }

    .p-filled + label, .p-inputwrapper-filled + label{
        top:9px !important;
        left:3px !important;
        font-size:12px;
        &.ems-textarea-label{
            top:50% !important;
            opacity: 0;
            font-size:14px;
        }
    }

    input:focus + label, textarea:focus + label, .p-inputwrapper-focus + label{
        top:9px !important;
        left:3px!important;
        font-size:12px;
        &.ems-textarea-label{
            top:50% !important;
            opacity: 0;
            font-size:14px;
        }
    }

   /* .ems-required-field{
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        right: 5px;
        top: 3px;
        color: $error-field-border;
        line-height: 1;
        z-index: 100;
    }*/

    .p-element:disabled{
        background: $disabled-color;
        opacity: 1;
        width: 100%;
        color: $black;
    }
    
    

    .p-multiselect{
        border:0px;
        width:162px;
        padding:6px 0px 4px 4px;
        font-size: 14px !important;
        background-color:transparent;

        .p-multiselect-trigger{
            background-color:transparent;
        }

        .p-multiselect-label{
            padding: 12px 0px 0px;
            color:#898989;
        }

        .ems-ms-item{
            padding:1px;
            margin:1px;
        }

    }

    .p-dropdown{
        padding:0px !important;
        width:162px;
        font-size: 14px !important;
        border:0px;
        .p-dropdown-label{
            padding:10px 0px 10px 4px;
        }
    }

    /*.ems-float-label{
        font-weight: normal;
        position: absolute !important;
        pointer-events: none;
        top:9px !important;
        left:3px !important;
        margin-top: -.5em;

        line-height: 1;
        font-size:12px;
    }*/

    /*.ems-multiselect-label{
        position: relative;
        top:-7px;
    }*/

    .p-multiselect-panel, .p-dropdown-panel{
        padding:4px;
        @include border-radius(0);
    }

    .p-inputtext{
        font-size:14px;
    }

}


/*.ems-input-disabled{
    .p-float-label{
        background:#ccc;
    }
}*/

/*.ems-content_v-centered{
    display: flex;
    justify-content: center;
    flex-direction: column;
}*/

.ems-button{
    height:44px;
    padding:0px 20px;
    border:1px solid transparent;
    font-weight:bold;
    font-size:14px;
}

.ems-button-primary{
    background:$button-border;
    border-color: $button-border;
    color:#FFF;
    &:hover{
        background:#1c6799;
        border-color: #1c6799;
    }
    &:focus{
      outline:none;
    }

    &:disabled{
        background:$border-color;
        border-color: $border-color;
    }
}

.ems-button-secondary{
    background: #FFF;
    border-color: #1788b5;
    color: #1788b5;
    &:hover{
        background:$button-border;
        border-color: $button-border;
        color:#FFF;
    }
}

.ems-icon{
    color:$button-border;
    font-size:28px !important;
    background:#FFF;
    &:disabled{
        border: 1px solid $disabled-color;
        cursor: not-allowed;
        color: $disabled-color !important;
        background: none !important;
    }
}

.ems-status{
    display: inline-block;
    width: 14px;
    height: 14px;
    line-height: 14px;
    @include border-radius(20px);
    span {
        vertical-align: middle;
    }
}

.ems-status-active{
    background:#7a9c49;
}

.ems-status-draft{
    background:#e8ab3b;
}

.ems-status-expired{
    background:#bf5549;
}

.ems-require-indexing {
    background: #FF5722;
}

.ems-inline{
    display: inline-block;
}

.ems-checkbox{
    $checkbox-size: 14px;

    .p-checkbox-label{
        font-size: $checkbox-size;
    }

    .p-checkbox{
        width: $checkbox-size;
        height: $checkbox-size;

        .p-checkbox-box{
            width: $checkbox-size !important;
            height: $checkbox-size !important;

            border: 1px solid $border-color;
            @include border-radius(0);
            box-shadow: none !important;

            &:hover{
                border-color: $border-color !important;
            }

            &.p-state-focus{
                border-color:$border-color !important;
                &:hover{
                    border-color:$border-color !important;
                }
            }

            &.p-highlight{
                border-color:$button-border !important;

                &:hover{
                    background:$button-border !important;
                    border-color:$button-border !important;
                }
            }

            .p-checkbox-icon	{
                width:$checkbox-size;
                height:$checkbox-size;
                font-size:12px;
                padding-right:2px;
            }
        }

    }

}

.ems-checkbox-medium{
    $checkbox-size: 22px;

    .p-checkbox-label{
        font-size: $checkbox-size;
    }

    .p-checkbox{
        width: $checkbox-size;
        height: $checkbox-size;

        .p-checkbox-box{
            width: $checkbox-size !important;
            height: $checkbox-size !important;


            .p-checkbox-icon	{
                width:$checkbox-size;
                height:$checkbox-size;
                font-size:14px;
                padding-right: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

    }
}

.p-multiselect-item .p-checkbox{
    margin:0px 0px 0px 4px !important;
    float:right;
}

.ems-attach-files{
    background:transparent;
    outline: none;
    color: #037dae;
    border: 1px solid #037dae;
    width:100%;
    &:hover{
        background:#037dae;
        color:#FFF;
    }

    .ems-attach-files_icon{
        font-size:32px;
    }

    .ems-attach-files_text{
        position: relative;
        top:-8px;
    }
}

.ems-add-auth{
    color:#037dae;
    font-size:14px;
    cursor:pointer;
    margin-top:2px;
}

.ems-dropdown {
    &.sdps-dropdown {
        vertical-align: middle;
        display : inline-block;
        padding-left: 10px;
        @include border-radius(0);
        border:0px;
    }

    .sdps-dropdown--lightGrey__select {
        background: $white;
        border:1px solid #a6a6a6;
    }

    .sdps-dropdown__icon {
        top: 55%;
        @include transform-translate(-90%, -50%);
        right: -5px;
    }

    .sdps-dropdown--lightGrey__select:hover {
        background: $white;
        border:1px solid #a6a6a6;
    }

    .sdps-dropdown__select:hover {
        border:1px solid #a6a6a6;
    }

    .sdps-dropdown__select {
        @include border-radius(0);
        height: 44px;
        outline:none;
        padding-right:36px;
        &:focus{
            border:1px solid #999;
        }
    }

    .sch-caret-down {
        font-size: 20px;
    }
}
